/* =============================================================================
 * Fonts
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face#Browser_compatibility
 * for information about which formats you need to support your target browsers.
============================================================================= */


@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=9f9c11de-ca5e-40f9-822a-220126de2433");

@font-face {
	font-family:"Gill Sans Book";
	src:url("gill-sans/c4be5717-c19c-43a5-9ad3-b548ddf070d4.eot?#iefix");
	src:url("gill-sans/c4be5717-c19c-43a5-9ad3-b548ddf070d4.eot?#iefix") format("eot"), url("gill-sans/4bfd13a9-3195-4d8f-87a9-83a212d18b16.woff2") format("woff2"), url("gill-sans/63aa9f40-04be-453a-a672-aa8f9919115d.woff") format("woff"), url("gill-sans/a82a5fb6-ad03-4675-801e-d3182a58ff5f.ttf") format("truetype"), url("gill-sans/beb2cee2-1a51-44ca-848f-4f795394ee5a.svg#beb2cee2-1a51-44ca-848f-4f795394ee5a") format("svg");
}

@font-face {
	font-family:"Gill Sans Medium";
	src:url("gill-sans/beab4258-af94-4971-a0db-b7bc2bef74bc.eot?#iefix");
	src:url("gill-sans/beab4258-af94-4971-a0db-b7bc2bef74bc.eot?#iefix") format("eot"), url("gill-sans/d9f9decc-5fa8-4390-a105-4f89f6b7b055.woff2") format("woff2"), url("gill-sans/cd5a93fc-2bb1-48df-8982-11119cd6e270.woff") format("woff"), url("gill-sans/b6bdc93b-2720-4c92-b68d-48499456178e.ttf") format("truetype"), url("gill-sans/944cce8d-20bf-41c1-ae29-2bc8199859db.svg#944cce8d-20bf-41c1-ae29-2bc8199859db") format("svg");
}

@font-face {
	font-family:"Gill Sans Bold";
	src:url("gill-sans/1a6dec8e-26f9-4243-8495-835709538f92.eot?#iefix");
	src:url("gill-sans/1a6dec8e-26f9-4243-8495-835709538f92.eot?#iefix") format("eot"), url("gill-sans/2029e71f-067d-46a8-bc44-b5c64a258928.woff2") format("woff2"), url("gill-sans/f05272f9-83e3-4de6-8423-5d57f730c87b.woff") format("woff"), url("gill-sans/33f5d8d9-105f-4a49-9351-74ecae7f4a49.ttf") format("truetype"), url("gill-sans/b56150eb-5caa-4385-b907-373e97ddb2ff.svg#b56150eb-5caa-4385-b907-373e97ddb2ff") format("svg");
}

@font-face {
	font-family:"Gill Sans Heavy";
	src:url("gill-sans/7beaeaeb-bdcc-4913-b21d-f5281f8abb66.eot?#iefix");
	src:url("gill-sans/7beaeaeb-bdcc-4913-b21d-f5281f8abb66.eot?#iefix") format("eot"), url("gill-sans/e4c25a9c-b7e5-4844-b86c-dee0626bd23a.woff2") format("woff2"), url("gill-sans/9b2779d5-46af-40b1-87bc-df494ec07194.woff") format("woff"), url("gill-sans/fd197301-816d-4f73-a3da-eb96ea722d25.ttf") format("truetype"), url("gill-sans/6edbedb4-b331-4dc9-b208-8e77a47406d4.svg#6edbedb4-b331-4dc9-b208-8e77a47406d4") format("svg");
}

/* @font-face {
	font-weight:normal;
	font-style:normal;
	font-family:"My Custom Font";
	src:url(MyCustomFont_Rg.woff) format("woff");
}
@font-face {
	font-weight:normal;
	font-style:italic;
	font-family:"My Custom Font";
	src:url(MyCustomFont_RgIt.woff) format("woff");
}
@font-face {
	font-weight:bold;
	font-style:normal;
	font-family:"My Custom Font";
	src:url(MyCustomFont_Bd.woff) format("woff");
}
@font-face {
	font-weight:bold;
	font-style:italic;
	font-family:"My Custom Font";
	src:url(MyCustomFont_BdIt.woff) format("woff");
} */
