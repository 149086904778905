/* =============================================================================
 * Common styling for unclassed HTML elements.
============================================================================= */


/* Viewport
----------------------------------------------------------------------------- */
html {
	background:#fff;
	color:#000;
	font:15px/24px "Gill Sans MT", Arial, sans-serif;
	font:var(--type-sz3);
}

@media screen and (min-width:768px) {
	body {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		flex-direction:column;
		min-height:100vh;
	}
}

@media screen and (max-width: 640px) {
	main {
		display:grid;
	}
}

/* Sub-section heading
 * Comments
----------------------------------------------------------------------------- */
*:focus,
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline:2px dotted currentcolor;
}
a:active,
button:active {
	outline:none;
}

/* Paragraphs
----------------------------------------------------------------------------- */
p {
	margin-top:0;
	margin-bottom:var(--vspace);
}


/* Links
----------------------------------------------------------------------------- */
a {
	color:var(--color-blue);
}

/*
a:visited {
	color:var(--color-blue);
}
*/
a:hover,
a:focus {
	color:var(--color-blue-dark);
}
a:active {
	outline:0;
}


/* Headings
----------------------------------------------------------------------------- */
h1 {
	margin:0 0 30px;
	margin:0 0 var(--vspace);
	font:var(--type-sz7-medium);
}
h2 {
	margin:0 0 30px;
	margin:0 0 var(--vspace);
	font:var(--type-sz6-medium);
}
h3 {
	margin:0 0 30px;
	margin:0 0 var(--vspace);
	font:var(--type-sz5-medium);
}
h4 {
	margin:0 0 calc(30px / 2);
	margin:0 0 calc(var(--vspace) / 2);
	font:var(--type-sz4-medium);
}
h5 {
	margin:0;
	font:var(--type-sz3-medium);
}
h6 {
	margin:0;
	font:var(--type-sz3-medium);
}


/* Lists
 * Global styling of unclassed lists.
 *
 * 1. Remove trailing margins from nested lists.
----------------------------------------------------------------------------- */
dl,
ol,
ul {
	margin-top:0;
	margin-bottom:var(--vspace);
}
li {
	margin-bottom:var(--space-s);
}
li > ul,
li > ol {
	margin-top:var(--space-s);
	margin-bottom:0; /* [1] */
}
dt {
	font-weight:bold;
}
dd + dt {
	margin-top:calc(var(--vspace) / 2);
}


/* Images
 *
 * 1. Fluid images for responsive purposes.
 * 2. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display:block;`.
 * 3. Keep correct aspect ratio when img is scaled down based on parent width.
----------------------------------------------------------------------------- */
img {
	max-width:100%; /* 1 */
	vertical-align:middle; /* 2 */
}
img[height] {
	height:auto; /* 3 */
}


/* Videos
 *
 * 1. Fluid videos for responsive purposes.
 * 2. Keep correct aspect ratio when video is scaled down based on parent width.
----------------------------------------------------------------------------- */
video {
	max-width:100%; /* 1 */
}
video[height] {
	height:auto; /* 2 */
}


/* Figure
----------------------------------------------------------------------------- */
figure {
	margin:0 0 var(--vspace) 0;
}
figure img {
	width:100%;
}
figcaption {
	margin-top:var(--space-xs);
	font-style:italic;
}


/* Forms
----------------------------------------------------------------------------- */
form {
	margin-bottom:var(--vspace);
}
fieldset {
	margin-bottom:var(--vspace);
}
label {
	display:inline-block;
	margin-bottom:5px;
}
select {
	width:100%;
}
input:not([type]),
input[type="text"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea {
	width:100%;
	padding:10px;
	border:1px solid #000;
	border-radius:0;
	background:#fff;
	color:#000;
}
textarea {
	height:200px;
}
input[disabled],
textarea [disabled],
button[disabled] {
	cursor:not-allowed;
}

/* Iframes
----------------------------------------------------------------------------- */
iframe {
	width:100%;
	height:100%;
	border:none;
}

/* Tables
----------------------------------------------------------------------------- */
table {
	width:100%;
	margin-bottom:var(--vspace);
	border-spacing:0;
	border-collapse:collapse;
}
caption {
	padding-bottom:15px;
	border-bottom:1px solid #ccc;
	font-weight:bold;
	text-align:left;
}
td,
th {
	border-bottom:1px solid #ccc;
	vertical-align:top;
}
th {
	padding:5px 20px 5px 0;
	text-align:left;
}
td,
tbody th {
	padding:15px 20px 15px 0;
}
tfoot {
	font-weight:bold;
}


/* Quotes
----------------------------------------------------------------------------- */
blockquote {
	display:flex;
	margin:0 0 var(--vspace);
	font:var(--type-sz4-medium);
}
blockquote::before {
	display:inline-block;
	margin-right:10px;
	color:var(--color-blue);
	font-size:104px;
	font-family:"Gill Sans Book", Arial, sans-serif;
	line-height:1.1;
	content:"”";
}
blockquote > * {
	flex:0 1 auto;
}
blockquote > *:last-child {
	margin-bottom:0;
}


/* Preformatted
----------------------------------------------------------------------------- */
pre {
	margin-bottom:var(--vspace);
}

@media screen and (min-width:768px) {
	html {
		font:var(--type-sz4);
	}
}
